import React, { useState , useEffect, useRef} from "react";
import GraphiQL from "graphiql";
import GraphiQLExplorer from "graphiql-explorer";
import { buildClientSchema, getIntrospectionQuery, parse } from "graphql";
import "graphiql/graphiql.css";
import "./App.css";

function fetcher(params) {
    const queryParams = new URLSearchParams(window.location.search);
    return fetch(
        `${process.env.REACT_APP_GRAPHQL_BACKEND_URL}?clientID=${queryParams.get('clientID')}&token=${queryParams.get('token')}`,
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params)
        }
    )
        .then(function(response) {
            return response.text();
        })
        .then(function(responseBody) {
            try {
                return JSON.parse(responseBody);
            } catch (e) {
                return responseBody;
            }
        });
}

// const DEFAULT_QUERY = `{
// tahkeem{
// seasons{
// id
// name
// }
// }}`;

const DEFAULT_QUERY = ``;

const App = ({})=> {
    let graphiql = useRef(null);

    const [state, setState] = useState({
        schema: null,
        query: DEFAULT_QUERY,
        explorerIsOpen: true
    });

    useEffect(() =>{
        if(graphiql){
            fetcher({
                query: getIntrospectionQuery()
            }).then(result => {
                const editor = graphiql.getQueryEditor();
                editor.setOption("extraKeys", {
                    ...(editor.options.extraKeys || {}),
                    "Shift-Alt-LeftClick": _handleInspectOperation
                });

                setState({
                    ...state,
                    schema: buildClientSchema(result.data) });
            });
        }
    },[graphiql]);

    const _handleInspectOperation = (
        cm,
        mousePos
    ) => {
        const parsedQuery = parse(state.query || "");

        if (!parsedQuery) {
            console.error("Couldn't parse query document");
            return null;
        }

        var token = cm.getTokenAt(mousePos);
        var start = { line: mousePos.line, ch: token.start };
        var end = { line: mousePos.line, ch: token.end };
        var relevantMousePos = {
            start: cm.indexFromPos(start),
            end: cm.indexFromPos(end)
        };

        var position = relevantMousePos;

        var def = parsedQuery.definitions.find(definition => {
            if (!definition.loc) {
                console.log("Missing location information for definition");
                return false;
            }

            const { start, end } = definition.loc;
            return start <= position.start && end >= position.end;
        });

        if (!def) {
            console.error(
                "Unable to find definition corresponding to mouse position"
            );
            return null;
        }

        var operationKind =
            def.kind === "OperationDefinition"
                ? def.operation
                : def.kind === "FragmentDefinition"
                ? "fragment"
                : "unknown";

        var operationName =
            def.kind === "OperationDefinition" && !!def.name
                ? def.name.value
                : def.kind === "FragmentDefinition" && !!def.name
                ? def.name.value
                : "unknown";

        var selector = `.graphiql-explorer-root #${operationKind}-${operationName}`;

        var el = document.querySelector(selector);
        el && el.scrollIntoView();
    };

    const _handleEditQuery = (query) => setState({  ...state, query });

    const _handleToggleExplorer = () => {
        setState({...state, explorerIsOpen: !state.explorerIsOpen });
    };

    const { query, schema } = state;
    return (
        <div className="graphiql-container">
            <GraphiQLExplorer
                schema={schema}
                query={query}
                onEdit={_handleEditQuery}
                onRunOperation={operationName =>
                    graphiql.handleRunQuery(operationName)
                }
                explorerIsOpen={state.explorerIsOpen}
                onToggleExplorer={_handleToggleExplorer}
            />
            <GraphiQL
                ref={ref => (graphiql = ref)}
                fetcher={fetcher}
                schema={schema}
                query={query}
                onEditQuery={_handleEditQuery}
            >
                <GraphiQL.Toolbar>
                    <GraphiQL.Button
                        onClick={() => graphiql.handlePrettifyQuery()}
                        label="Prettify"
                        title="Prettify Query (Shift-Ctrl-P)"
                    />
                    <GraphiQL.Button
                        onClick={() => graphiql.handleToggleHistory()}
                        label="History"
                        title="Show History"
                    />
                    <GraphiQL.Button
                        onClick={_handleToggleExplorer}
                        label="Explorer"
                        title="Toggle Explorer"
                    />
                </GraphiQL.Toolbar>
            </GraphiQL>
        </div>
    );
};

export default App;
